<template>
  <span
    ref="spanRef"
    :class="cn('inline-block  text-black dark:text-white ', props.class)"
  >
    {{ output }}+
  </span>
</template>

<script setup lang="ts">
import {
  TransitionPresets,
  useTransition,
  useElementVisibility,
} from '@vueuse/core'
import { cn } from '~/lib/utils'

type TransitionsPresetsKeys = keyof typeof TransitionPresets

interface NumberTickerProps {
  class?: string
  decimalPlaces?: number
  delay?: number
  direction?: 'up' | 'down'
  duration?: number
  transition?: TransitionsPresetsKeys
  value: number
}

const spanRef = ref<HTMLSpanElement>()

const props = withDefaults(defineProps<NumberTickerProps>(), {
  class: undefined,
  decimalPlaces: 0,
  delay: 0,
  direction: 'up',
  duration: 2500,
  transition: 'easeOutCubic',
  value: 0,
})

const transitionValue = ref(props.direction === 'down' ? props.value : 0)

const transitionOutput = useTransition(transitionValue, {
  delay: props.delay,
  duration: props.duration,
  transition: TransitionPresets[props.transition],
})

const output = computed(() => {
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: props.decimalPlaces,
    maximumFractionDigits: props.decimalPlaces,
  }).format(Number(transitionOutput.value.toFixed(props.decimalPlaces)))
})

const isInView = useElementVisibility(spanRef, {
  threshold: 0,
})

watch(
  isInView,
  (isVisible) => {
    if (isVisible) {
      transitionValue.value = props.direction === 'down' ? 0 : props.value
    }
  },
  { immediate: true }
)
</script>
